import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { ADMIN_PREFIX_PATH } from 'configs/AppConfig'

export const AdminViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${ADMIN_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`${ADMIN_PREFIX_PATH}/financeiro`} component={lazy(() => import(`./financeiro`))} />
        <Route path={`${ADMIN_PREFIX_PATH}/unidade/:id`} component={lazy(() => import(`./unidade`))} />
        <Route path={`${ADMIN_PREFIX_PATH}/lista-unidades`} component={lazy(() => import(`./lista-unidade`))} />
        <Redirect from={`${ADMIN_PREFIX_PATH}`} to={`${ADMIN_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

export default AdminViews;

