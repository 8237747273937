import { io } from "socket.io-client";
import { env } from "../configs/EnvironmentConfig";

const URL = env.API_SOCKET_ENDPOINT_URL + "/chat";
const URL_PACIENTES = env.API_SOCKET_ENDPOINT_URL + "/chat-app-emagrecer";

export const socket = io(URL, {
	path: env.API_SOCKET_SUFIX_PATH,
});

export const socketPacientes = io(URL_PACIENTES, {
	path: env.API_SOCKET_SUFIX_PATH,
});
