// TODO: após clonar o skeleton, ajustar os links de ambientes

const dev = {
	API_ENDPOINT_URL: "http://localhost:8087/emagrecer/api",
	SOCKET_ENDPOINT_URL: "http://localhost:8080",
	SITE_URL: "http://localhost:3000",
	PUBLIC_PREFIX_PATH: "",
	API_SOCKET_ENDPOINT_URL: "http://localhost:8087",
	API_SOCKET_SUFIX_PATH: "/socket.io",
};

const prod = {
	API_ENDPOINT_URL:
		"https://emagrecer-backend-zkqaj.ondigitalocean.app/emagrecer-backend2/emagrecer/api",
	SOCKET_ENDPOINT_URL:
		"https://emagrecer-backend-zkqaj.ondigitalocean.app/emagrecer-backend2",
	SITE_URL: "https://emagrecer-backend-zkqaj.ondigitalocean.app",
	PUBLIC_PREFIX_PATH: "",
	API_SOCKET_ENDPOINT_URL:
		"https://emagrecer-backend-zkqaj.ondigitalocean.app",
	API_SOCKET_SUFIX_PATH: "/emagrecer-backend2/emagrecer/socket.io",
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/emagrecer-backend/api",
	SOCKET_ENDPOINT_URL: "https://homolog.dimo.com.br",
	PUBLIC_PREFIX_PATH: "/emagrecer",
	SITE_URL: "https://homolog.dimo.com.br",
	API_SOCKET_ENDPOINT_URL: "https://homolog.dimo.com.br",
	API_SOCKET_SUFIX_PATH: "/emagrecer/socket.io",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			break;
	}
};

export const env = getEnv();
