import {
  SET_IS_LOADING,
  SET_TOKEN_EXPIRED,
} from '../constants/Gui';

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    isLoading
  }
};

export const setTokenExpired = (isExpired) => {
  return {
    type: SET_TOKEN_EXPIRED,
    isExpired
  }
};