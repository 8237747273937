import fetch from 'auth/FetchInterceptor'
import { UPDATE_USER, UPDATE_UNIDADE } from 'redux/constants/User';
import { UPDATE_PERMISSOES } from 'redux/constants/Permissoes';
import store from 'redux/store'

const menuService = {}

/*
    Injeta na store do usuário os dados do menu
*/

menuService.populate = (menuData) =>{
    store.dispatch({
        type: UPDATE_USER,
        name: menuData.user.nome,
        role: "Usuário",
        unidade: menuData.user.unidade,
        idusuario: menuData.user.idusuario,
        franqueador_acess: menuData.user.franqueador_acess,
    });
    

    store.dispatch({
        type: UPDATE_PERMISSOES,
        permissoes: menuData.permissoes,
    });
}

menuService.populateMenuData = async function () {
    const userState = store.getState().user;
    const authState = store.getState().auth;
    if(!userState.name && authState.token) {
        const menuData = await menuService.getMenuData();
        menuService.populate(menuData)
    }
}

menuService.populateUnidade = async function () {
   
    const menuData = await menuService.getMenuData();
    
    store.dispatch({
        type: UPDATE_UNIDADE,
        unidade: menuData.user.unidade
    });
    
}

menuService.getMenuData = function () {
	return fetch({
		url: '/v1/rest/menu',
		method: 'get'
	})
}

export default menuService