import React from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import DevicesLayout from 'layouts/devices-layout';
import AppLocale from 'lang';
import {IntlProvider} from 'react-intl';
import {ConfigProvider} from 'antd';
import {ADMIN_PREFIX_PATH, APP_PREFIX_PATH, AUTH_PREFIX_PATH, DEVICES_PREFIX_PATH, TREINAMENTO_PREFIX_PATH, DOCS_PREFIX_PATH} from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import TreinamentoLayout from "../layouts/treinamento-layout";
import ClienteSenha from './devices-views/cliente-senha';
import AdminLayout from 'layouts/admin-layout';
import { DocsViews } from "views/docs-views";

function RouteInterceptor({children, isAuthenticated, ...rest}) {
	return (
		<Route
			{...rest}
			render={({location}) =>
				isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: AUTH_PREFIX_PATH,
							state: {from: location},
						}}
					/>
				)
			}
		/>
	);
}


export const Views = props => {
	const {locale, token, location, direction} = props;
	const currentAppLocale = AppLocale[locale];
	useBodyClass(`dir-${direction}`);
	return (
		<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
			<ConfigProvider locale={currentAppLocale.antd} direction={direction}>
				<Switch>
					<Route exact path="/">
						<Redirect to={APP_PREFIX_PATH} />
					</Route>
					<Route exact path="/">
						<Redirect to={TREINAMENTO_PREFIX_PATH} />
					</Route>
					<Route exact path="/">
						<Redirect to={DEVICES_PREFIX_PATH} />
					</Route>
					<Route exact path="/">
						<Redirect to={ADMIN_PREFIX_PATH} />
					</Route>
					<Route
						component={DocsViews}
						path={DOCS_PREFIX_PATH}
					/>
					<Route path={AUTH_PREFIX_PATH}>
						<AuthLayout direction={direction} />
					</Route>
					<Route path={`${DEVICES_PREFIX_PATH}/cliente-senha/:id`}>
						<ClienteSenha />
					</Route>
					<RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
						<AppLayout direction={direction} location={location} />
					</RouteInterceptor>
					<RouteInterceptor path={DEVICES_PREFIX_PATH} isAuthenticated={token}>
						<DevicesLayout direction={direction} location={location} />
					</RouteInterceptor>
					<RouteInterceptor path={TREINAMENTO_PREFIX_PATH} isAuthenticated={token}>
						<TreinamentoLayout direction={direction} location={location} />
					</RouteInterceptor>
					<RouteInterceptor path={ADMIN_PREFIX_PATH} isAuthenticated={token}>
						<AdminLayout direction={direction} location={location} />
					</RouteInterceptor>
				</Switch>
			</ConfigProvider>
		</IntlProvider>
	);
};

const mapStateToProps = ({theme, auth}) => {
	const {locale, direction} = theme;
	const {token} = auth;
	return {locale, token, direction};
};

export default withRouter(connect(mapStateToProps)(Views));
