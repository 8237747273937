import { Button, Col, Form, Input, Row } from "antd";
import Loading from "components/atom/Loading"
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { authenticatedAdmin } from "redux/actions/Auth";
import JwtAuthService from "services/JwtAuthService";

const AdminLoginModal = (props) => {
    const { closeModal, authenticatedAdmin } = props;

    const [isLoading, setIsLoading] = useState(false);
    
    const [form] = Form.useForm();

    let history = useHistory();

    const onFinish = async (values) => {
        setIsLoading(true);
        const auth = await JwtAuthService.loginAdmin(values.senha);
        if(auth.token_admin){
            const token_admin = `${auth.token_type} ${auth.token_admin}`;;
            authenticatedAdmin(token_admin);
            history.push(ADMIN_PREFIX_PATH)
            closeModal();
        }
        setIsLoading(false);
    }

    return (
        <Loading isLoading={isLoading}>
            <Form form={form} onFinish={onFinish} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout={"vertical"}> 
                <Row>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Senha"
                            name="senha"
                            rules={[
                                { required: true, message: 'Por favor, digite a senha do painel administrativo.' }
                            ]}
                            >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{float: "right"}}>
                                Entrar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Loading>
    )
}
  
const mapDispatchToProps = {
    authenticatedAdmin
};

export default connect(()=>{return{}}, mapDispatchToProps)(AdminLoginModal);